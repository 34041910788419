import { Center, Flex, HStack, Select, Text } from '@chakra-ui/react';
import { useTranslation } from '@/hooks/useTranslation';
import { useRouter } from 'next/router';
import type { ChangeEvent } from 'react';
import { useMemo } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { useListContext } from './ListContext';
import { IconButtonV2 } from '@/components/ui/IconButtonV2';

export type ListPaginationProps = {
  pageSizes?: boolean | number[];
};

export const ListPagination = ({ pageSizes = false }: ListPaginationProps) => {
  const router = useRouter();
  const { t } = useTranslation(['common']);
  const { currentPage, currentPageSize, pageMeta, pageLabel } = useListContext();

  const maxPageSize = useMemo(() => {
    if (Array.isArray(pageSizes)) {
      return Math.max(...pageSizes);
    }
    return 100;
  }, [pageSizes]);

  const pageSizesArray = useMemo(() => {
    let defaultPageSizeList = [10, 20, 50, 100];
    if (Array.isArray(pageSizes)) {
      defaultPageSizeList = pageSizes;
    }
    return defaultPageSizeList.filter((size, index, arr) => {
      if (!index) {
        return size <= maxPageSize && (pageMeta?.totalCount ?? 0) > size;
      }
      return size <= maxPageSize && (pageMeta?.totalCount ?? 0) > arr[index - 1];
    });
  }, [maxPageSize, pageMeta?.totalCount, pageSizes]);

  const pagesToDisplay = useMemo(() => {
    return [
      ...new Set([
        1,
        currentPage - 2,
        currentPage - 1,
        currentPage,
        currentPage + 1,
        currentPage + 2,
        ...(pageMeta ? [pageMeta.pageCount] : []),
      ]),
    ].filter((page) => page > 0 && page <= (pageMeta ? pageMeta.pageCount : 1));
  }, [currentPage, pageMeta]);

  const handlePageChange = (page: number) => {
    router.replace(
      {
        query: { ...router.query, [pageLabel]: page },
      },
      undefined,
      { scroll: false },
    );
  };

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newPageSize = parseInt(event.target.value, 10);
    let newPage = currentPage;
    // if keeping the currentPage number would be too far and would return 0 results
    if (pageMeta?.totalCount && pageMeta.totalCount < newPageSize * currentPage) {
      newPage = Math.ceil(pageMeta.totalCount / newPageSize);
    }
    router.replace(
      {
        query: { ...router.query, limit: newPageSize, [pageLabel]: newPage },
      },
      undefined,
      { scroll: false },
    );
  };

  if (!pageMeta) {
    return null;
  }

  return (
    <Flex alignItems="center" gap={2} mt={1}>
      {!!pageMeta.totalCount && (
        <Text flex={1} color="text3" fontSize="sm" fontWeight="medium">
          {t('common:table_result_count', { count: pageMeta.totalCount })}
        </Text>
      )}
      {pageSizes && pageSizesArray.length > 1 && (
        <HStack flex={1} justifyContent={'center'}>
          <Select
            value={currentPageSize}
            onChange={handlePageSizeChange}
            width="5rem"
            borderRadius="lg"
            borderWidth={0}
            backgroundColor="gray.100"
            _hover={{ backgroundColor: 'gray.200' }}
          >
            {pageSizesArray.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
          <Text>{t('common:lines_per_page')}</Text>
        </HStack>
      )}
      {(pagesToDisplay.length > 1 && (
        <HStack flex={1} justifyContent="flex-end">
          <IconButtonV2
            disabled={!pageMeta.hasPreviousPage}
            variant="ghost"
            label={t('common:previous')}
            IconComponent={HiChevronLeft}
            onClick={() => {
              if (pageMeta.hasPreviousPage) {
                handlePageChange(currentPage - 1);
              }
            }}
          />

          {pagesToDisplay.map((page: number) => {
            return (
              <Center
                key={page}
                transition="all 0.2s"
                onClick={() => {
                  handlePageChange(page);
                }}
                cursor="pointer"
                fontSize="sm"
                fontWeight={currentPage === page ? 'bold' : 'normal'}
                borderRadius="xl"
                color={currentPage === page ? 'primary.text' : 'gray.600'}
                backgroundColor={currentPage === page ? 'primary.50' : 'transparent'}
                height="2rem"
                width="2rem"
              >
                {page}
              </Center>
            );
          })}
          <IconButtonV2
            disabled={!pageMeta.hasNextPage}
            variant="ghost"
            label={t('common:next')}
            IconComponent={HiChevronRight}
            onClick={() => {
              if (pageMeta.hasNextPage) {
                handlePageChange(currentPage + 1);
              }
            }}
          />
        </HStack>
      )) || <HStack flex={1} />}
    </Flex>
  );
};
