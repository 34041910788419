import { Flex, Tag } from '@chakra-ui/react';
import Link from 'next/link';

export const ListSelectedTags = ({
  selectedServices,
}: {
  selectedServices: Map<string, string>;
}) => {
  return (
    <Flex gap={2} flexWrap={'wrap'} pb={2}>
      {Array.from(selectedServices).map(([key, value]) => (
        <Tag key={key} as={Link} colorScheme="primary" href={`/admin/services/${key}`}>
          {value}
        </Tag>
      ))}
    </Flex>
  );
};
