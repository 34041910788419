import { Box } from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';

interface WithLinkProps {
  link?: string;
  children: React.ReactNode;
}

export const WithLink: React.FC<WithLinkProps> = ({ link, children }) =>
  link ? (
    <Link href={link}>
      {/* Allow the link to extend until the first parent relative element */}
      <Box
        _before={{
          position: 'absolute',
          content: '""',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {children}
      </Box>
    </Link>
  ) : (
    children
  );
