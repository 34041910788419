import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import type { FC } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, isAfter, isValid } from 'date-fns';
import InputFieldBase from '../forms/formFields/InputFieldBase';

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (from: Date, to: Date) => void;
  from?: Date;
  to?: Date;
}

const oneYearBefore = new Date(new Date().setMonth(new Date().getMonth() - 12));

const DateFilterModal: FC<FilterModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  from = oneYearBefore,
  to = new Date(),
}) => {
  const { t } = useTranslation(['common', 'dashboard']);
  const [selectedFromDate, setSelectedFromDate] = useState<Date>(from);
  const [selectedToDate, setSelectedToDate] = useState<Date>(to);
  const [error, setError] = useState<string>('');

  const handleFromDateChange = (value: string) => {
    const date = new Date(value);
    if (isValid(date)) {
      setSelectedFromDate(date);
    }
    setError(isAfter(date, selectedToDate) ? t('common:error_range_date') : '');
  };

  const handleToDateChange = (value: string) => {
    const date = new Date(value);
    if (isValid(date)) {
      setSelectedToDate(date);
    }
    setError(isAfter(selectedFromDate, date) ? t('common:error_range_date') : '');
  };

  const isButtonDisabled = !selectedFromDate || !selectedToDate || error.length > 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('common:filter_by_date')}</ModalHeader>
        <ModalBody>
          <InputFieldBase
            placeholder={t('common:from_date')}
            label={t('common:from_date')}
            size="md"
            type="date"
            value={format(selectedFromDate, 'yyyy-MM-dd')}
            error={error}
            onChange={handleFromDateChange}
          />
          <InputFieldBase
            mt={4}
            label={t('common:to_date')}
            placeholder={t('common:to_date')}
            size="md"
            type="date"
            value={format(selectedToDate, 'yyyy-MM-dd')}
            onChange={handleToDateChange}
            error={error}
          />
        </ModalBody>
        <ModalFooter>
          <Button mr={2} onClick={onClose}>
            {t('common:cancel')}
          </Button>
          <Button
            colorScheme="primary"
            onClick={() => onSubmit(selectedFromDate, selectedToDate)}
            isDisabled={isButtonDisabled}
          >
            {t('common:apply')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DateFilterModal;
