import type { PageDto, RequestParams } from '@/generated/typing';
import { Flex } from '@chakra-ui/react';
import type { ColumnDef, Row } from '@tanstack/react-table';
import { type FC, type ReactNode } from 'react';
import { ListDateFilter } from './ListDateFilter';
import { ListPagination } from './ListPagination';
import { ListResults } from './ListResults';
import { ListSearch } from './ListSearch';
import { ListProvider } from './ListContext';
import type { ListQueryParams } from './types';
import { ListStatusFilter } from './ListStatusFilter';
import { ListSelectedTags } from './ListSelectedTag';
import type { QueryKey } from '@tanstack/react-query';

interface ListProps<TItem> {
  queryLabel?: string;
  columns: ColumnDef<TItem, string>[];
  queryCacheName: QueryKey;
  withSearch?: boolean;
  withStatusFilter?: boolean;
  withDateFilter?: boolean;
  query: (query: ListQueryParams, params?: RequestParams) => Promise<PageDto & { data?: TItem[] }>;
  selection?: {
    selected: string[];
    onChange: (selected: string[]) => void;
    keyProperty: keyof TItem;
    displaySelected?: boolean;
    maxSelected?: number;
    onSelectRow?: (selected: TItem[]) => void;
  };
  actionSection?: ReactNode;
  disablePagination?: boolean;
  pageSizes?: boolean | number[];
  section?: string;
  expandedRow?: FC<{ row: Row<TItem> }>;
  labels?: Map<string, string>;
  rowHref?: (row: TItem) => string;
}

export const List = <TItem extends { id: string }>({
  queryLabel = 'q',
  columns,
  query,
  queryCacheName,
  withSearch = false,
  withStatusFilter = false,
  withDateFilter = false,
  selection,
  actionSection,
  disablePagination = false,
  pageSizes,
  section,
  expandedRow,
  labels,
  rowHref,
}: ListProps<TItem>) => {
  return (
    <ListProvider
      query={query}
      queryCacheName={queryCacheName}
      queryLabel={queryLabel}
      defaultLimit={Array.isArray(pageSizes) ? pageSizes[0] : undefined}
      section={section}
    >
      {(withSearch || actionSection || withDateFilter || withStatusFilter) && (
        <Flex borderColor="gray.100" flexWrap={{ base: 'wrap', lg: 'nowrap' }} mb={4}>
          {withSearch && <ListSearch />}
          <Flex flex={1} gap={2}>
            {withDateFilter && <ListDateFilter />}
            {withStatusFilter && <ListStatusFilter />}
          </Flex>
          <Flex flex={1} justify="flex-end" gap={2}>
            {actionSection}
          </Flex>
        </Flex>
      )}
      {selection?.displaySelected && labels && <ListSelectedTags selectedServices={labels} />}
      <ListResults<TItem> {...{ selection, columns, expandedRow, rowHref }} />
      {!disablePagination && <ListPagination pageSizes={pageSizes} />}
    </ListProvider>
  );
};
